// eslint-disable-next-line import/no-unresolved
import Config from 'config';
import { isLocalhost } from 'utils/networkUtils';

function getEnvConfig() {
  const hostname = window && window.location && window.location.hostname;
  const devRegEx = /netlify.app|netlify.com|crediblemind.net|development.crediblemind.com/gi;
  if (isLocalhost || devRegEx.test(hostname)) {
    return Config.PROD;
  }
  return Config.PROD;
}

export default getEnvConfig();
