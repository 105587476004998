import React, { useEffect, useState } from 'react';
import Config from 'utils/getEnvConfig';
import { getLocalData, setLocalData } from 'utils/localDataStore';
import { getSubdomain, isValidCSSColor } from 'utils/stringUtils';
import qs from 'qs';
// import SecurityModal from 'components/SecurityModal';
import Main from 'containers/Main';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import { isEmbedded, hasDynamicIframeHeight } from 'utils/embedded';
import { getClientRedirectUrl, isClosedClient } from 'utils/clientRedirects';
import useBrowserBackStack from '../../components/Hooks/useBrowserBackStack';
import useManifest from '../../components/Hooks/useManifest';

// const getSecurityCheckInfo = ({ config, env, brand }) => {
//   // development.crediblemind.com -> check dev_password_required
//   // development.crediblemind.com?brand=x -> check dev_password_required && ! no in unprotected clients
//   // crediblemind.com -> check prod_password_required
//   // crediblemind.com?brand=x -> check prod_protected_clients matches with brand
//   // x.crediblemind.com -> check prod_protected_clients matches with brand
//   const info = {
//     passwordProtected: true,
//     password: _get(config, `${env}_password`),
//   };
//   if (brand && brand !== 'development') {
//     const clientsList = _get(
//       config,
//       `${env}_${env === 'dev' ? 'un' : ''}protected_clients`,
//       [],
//     );
//     const isBrandInList =
//       env === 'prod'
//         ? clientsList.find(client => _get(client, 'brand') === brand)
//         : clientsList.includes(brand);
//     if (env === 'prod' && !_isEmpty(isBrandInList)) {
//       return {
//         ...info,
//         password: _get(isBrandInList, 'password'),
//       };
//     }
//     if (!isBrandInList) {
//       return info;
//     }
//     return {};
//   }
//   if (_get(config, `${env}_password_required`)) return info;
//   return {};
// };

function getClientRedirectionUrl(subdomain, pathname) {
  return getClientRedirectUrl(subdomain, pathname) || null;
}

const removeLoader = () => {
  const loaderEl = document.getElementById('full-page-loader');
  if (loaderEl) loaderEl.parentNode.removeChild(loaderEl);
};

const setupDynamicHeight = isEmbed => {
  const app = document.getElementById('app');
  if (app && isEmbed && hasDynamicIframeHeight()) {
    app.setAttribute('style', 'overflow: hidden');
  }
};

const setBackgroundColor = stylesProps => {
  if (stylesProps) {
    const bgColor = _get(stylesProps, 'backgroundColor');
    if (isValidCSSColor(bgColor)) {
      const appEl = document.getElementById('app');
      appEl.style.backgroundColor = bgColor;
    }
  }
};

const clientEmbeddedInfo = _get(Config, 'CLIENT_EMBEDDED_INFO', {});

function HomePageWrapper() {
  const { search } = window.location;
  const [authenticated] = useState(getLocalData('isAuthenticated'));
  const searchObject = qs.parse(search, {
    ignoreQueryPrefix: true,
  });

  const subdomain = getSubdomain();
  const isEmbed = isEmbedded();
  const { pathname } = window.location;
  // const env = subdomain === 'development' ? 'dev' : 'prod';
  const embeddedUrl = subdomain
    ? _get(clientEmbeddedInfo, `${subdomain.toLowerCase()}.url`, '')
    : '';
  useEffect(removeLoader, []);
  useEffect(() => setupDynamicHeight(isEmbed), [isEmbed]);
  useEffect(() => setBackgroundColor(_get(window, 'xprops.styles')), [
    _get(window, 'xprops'),
  ]);
  useEffect(() => {
    if (authenticated) {
      setLocalData('isAuthenticated', 'true', { expires: 365 });
    }
  }, [authenticated]);

  useManifest();
  useBrowserBackStack();

  if (!isEmbed && embeddedUrl && !_isEmpty(embeddedUrl)) {
    const cleanPathname = pathname.replace(/^\/+/g, '');
    const encodedPathname = encodeURIComponent(cleanPathname);
    const finalRedirectUrl = `${embeddedUrl}${
      encodedPathname ? `?page=${encodedPathname}` : ''
    }`;
    window.location.href = finalRedirectUrl;
    return null;
  }

  const handleRedirect = () => {
    const clientRedirectionUrl = getClientRedirectionUrl(subdomain, pathname);
    if (clientRedirectionUrl) {
      // Add a message for closed clients
      if (isClosedClient(subdomain)) {
        console.info(
          `Redirecting from closed client ${subdomain} to ${clientRedirectionUrl}`,
        );
      }
      window.location.href = clientRedirectionUrl;
      return true;
    }
    return false;
  };

  if (handleRedirect()) {
    return null;
  }

  // const securityConfig =
  //   env === 'prod'
  //     ? _get(Config, 'PROD.SECURITY', null)
  //     : _get(Config, 'DEV.SECURITY', null);
  const searchBrand = _get(searchObject, 'brand', null);
  const brand = searchBrand || subdomain;
  if (brand && brand !== 'development') {
    setLocalData('brand', brand, {
      expires: 365,
    });
  }
  // const { passwordProtected, password } = getSecurityCheckInfo({
  //   config: securityConfig,
  //   env,
  //   brand,
  // });

  // if (
  //   isBot() ||
  //   !securityConfig ||
  //   !passwordProtected ||
  //   authenticated ||
  //   !password
  // ) {
  //   return <Main />;
  // }
  return <Main />;

  // return (
  //   <SecurityModal setAuthenticated={setAuthenticated} password={password} />
  // );
}

export default HomePageWrapper;
